<template>
    <div>
        <div class="container-fluid core-view d-flex flex-column">
            <LList
                analytics-prefix="Processes"
                :columns="columns"
                :companies="[]"
                :items="processes"
                model-name="Process"
                :loading="loading"
                :fetching="fetching"
                :total-item-count="totalProcessCount"
                :tooltip-wording="tooltipWording"
                @fetch="fetch"
            >
                <template
                    v-if="canManage"
                    slot="action-buttons"
                >
                    <RouterLink
                        :to="{name: 'admin.processes.add'}"
                        aria-label="Add Process"
                        data-cy="addProcessBtn"
                        class="btn btn-primary"
                    >
                        <FontAwesomeIcon
                            icon="plus"
                            class="mr-1"
                        />

                        Process
                    </RouterLink>
                </template>

                <template #list-item-action-buttons="{ item }">
                    <button
                        v-if="canManage && !item.obsoleteAt"
                        v-b-tooltip.hover.left
                        data-cy="runJobBtn"
                        class="btn btn-link btn-run btn-admin"
                        title="Run Latest Version as new Job"
                        aria-label="Run Latest Version as new Job"
                        @click="run(item)"
                    >
                        <FontAwesomeIcon
                            icon="play"
                            class="fas fa-2x fa-fw"
                        />
                    </button>

                    <button
                        v-b-tooltip.hover.left
                        class="btn btn-link btn-edit btn-admin"
                        title="Show Jobs of this Process"
                        aria-label="Show Jobs of this Process"
                        @click="$emit('process-view-jobs', item.id)"
                    >
                        <FontAwesomeIcon
                            icon="history"
                            class="far fa-2x fa-fw"
                        />
                    </button>

                    <RouterLink
                        v-b-tooltip.hover.left
                        data-cy="editProcessBtn"
                        :to="{name: 'admin.processes.edit', params: {id: item.id}}"
                        class="btn btn-link btn-options btn-admin"
                        title="Process Options"
                        aria-label="Process Options"
                    >
                        <FontAwesomeIcon
                            icon="cog"
                            class="fas fa-2x fa-fw mr-0"
                        />
                    </RouterLink>
                </template>
            </LList>
        </div>

        <RouterView />
    </div>
</template>

<script>
    import LList from '@imt/vue-list/src/components/LList.vue';
    import LStatusIndicator from '@imt/vue-list/src/components/LStatusIndicator.vue';
    import {asString} from '@imt/vue-list/src/utils/queryParams';
    import dateMixin from '@imt/vue-toolbox/src/mixins/date';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import toolboxUtils from '@imt/vue-toolbox/src/utils/index';
    import VersionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {mapActions, mapGetters, mapState} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHProcesses',
        components: {
            LList,
        },
        mixins: [
            authMixin,
            VersionMixin,
            dateMixin,
            toastsMixin,
        ],
        props: {
            mode: {
                type: String,
                required: false,
                default: 'light'
            }
        },
        data() {
            return {
                loading: true,
                fetching: false,
                totalProcessCount: 0,
                tooltipWording: {
                    search_field_display: {},
                    search_field_names: {
                        text: 'Text Search',
                        title: 'Title',
                        search: 'Text search within Title and Code',
                        updated_by: 'Modified By',
                    },
                },
                columns: [
                    {
                        key: 'title',
                        class: 'col-6 col-md-3 text-break',
                        value: item => {
                            return this.sortVersions(item)[0].title;
                        },
                        sortable: true,
                        sortBy: 'versions__title',
                    },
                    {
                        key: 'code',
                        class: 'col-4 col-lg-2 text-break d-none d-md-inline',
                        value: item => {
                            return item.code;
                        },
                        sortable: true,
                        sortBy: 'code',
                    },
                    {
                        key: 'policySystem',
                        class: 'col-2 text-break d-none d-lg-inline',
                        value: item => {
                            return item.policySystemId
                                ? this.formattedPolicySystems?.filter(ps => ps.id === item.policySystemId.toString())[0]?.title
                                : 'All';
                        },
                        sortable: false,
                    },
                    {
                        key: 'modified',
                        class: 'col-2 d-none d-lg-inline',
                        value: item => {
                            return item.updatedBy
                                ? `${this.dateFormatted(item.updatedAt)} by ${item.updatedBy.username}`
                                : `${this.dateFormatted(item.createdAt)} by ${item.createdBy.username}`;
                        },
                        sortable: true,
                        sortBy: 'updated_at',
                    },
                    {
                        key: 'status',
                        class: 'col-1 d-none d-sm-inline page-status',
                        component: LStatusIndicator,
                        sortable: false,
                        options: {
                            disableConflicts: true,
                            wording: {
                                not_published: 'Not yet published',
                                published: 'Published',
                                scheduled: 'Scheduled to be published',
                            },
                            statusFrom: item => {
                                return this.sortVersions(item)[0];
                            },
                        },
                    },
                    {
                        key: 'options',
                        class: 'col-auto options px-0 ml-auto'
                    }],
            };
        },
        computed: {
            ...mapGetters('toolbox', ['formattedPolicySystems']),
            ...mapState(['processes']),
        },
        watch: {
            async '$route.name'(newValue) {
                // User navigated back to the list from the Add Modal, Options Modal, or some other route
                // Re-fetch all processes here so we can pass the query string to the action
                if (newValue === 'admin.processes.list') {
                    await this.fetch();
                }
            },
        },
        async created() {
            await Promise.all([
                this.fetchPolicySystems(),
                this.fetch()
            ]);

            this.loading = false;
        },
        methods: {
            async fetch() {
                this.fetching = true;
                const response = await this.fetchProcesses(asString(this.$route.query));
                this.totalProcessCount = response.data.meta.pagination.count;
                this.fetching = false;
            },
            async run(item) {
                try {
                    let response = await this.runJob({processId: item.id});

                    this.success(`Job started successfully. ID: ${response.id}`, 'Run Job');
                } catch (err) {
                    toolboxUtils.console.log(err);
                    this.error('Job failed to start!', 'Run Job');
                }
            },
            ...mapActions(['fetchProcesses',]),
            ...mapActions('toolbox', ['fetchPolicySystems']),
            ...mapActions('builder', ['runJob']),
        }
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/modes/dark/colors"

    .mode.dark
        .ipa-toolbar,
        .list-header,
        .list-header > div
            background-color: lighten($dark, 8%)

        .ipa-toolbar
            .form-control
                background: var(--kc-gray-50)
                border-color: var(--kc-gray-600) !important
                color: var(--kc-gray-100)

                &:focus
                    background-color: lighten($kc-gray-50, 5%)

        .list-header
            .sortable-column-heading
                &:hover
                    background-color: lighten($dark, 15%)

        .page-number
            color: var(--kc-gray-200)

            &.active
                color: var(--kc-gray-100)

        .list
            .list-items
                > *:nth-child(even)
                    background-color: lighten($dark, 6%)

                .list-item-container
                    border-bottom-color: lighten($dark, 12%)

                .fa,
                .far,
                .fas
                    color: $primary

                    &:hover
                        color: lighten($primary, 20%)
</style>
